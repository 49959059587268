import React, { createContext } from 'react';
import { firestoreService } from '../services/firebase';

export interface HourlyRate {
    amount: number;
    currency: string;
}

export interface Membership {
    userId: string;
    hourlyRate: HourlyRate;
    targetId: string;
    membershipType: 'WORKSPACE' | 'PROJECT' | 'USERGROUP' | string;
    membershipStatus: 'ACTIVE' | string;
}

export interface WorkspaceSettings {
    timeRoundingInReports: boolean;
    onlyAdminsSeeBillableRates: boolean;
    onlyAdminsCreateProject: boolean;
    onlyAdminsSeeDashboard: boolean;
    defaultBillableProjects: boolean;
    lockTimeEntries: Date;
    round: {
        round: string;
        minutes: string;
    };
    projectFavorites: boolean;
    canSeeTimeSheet: boolean;
    canSeeTracker: boolean;
    projectPickerSpecialFilter: boolean;
    forceProjects: boolean;
    forceTasks: boolean;
    forceTags: boolean;
    forceDescription: boolean;
    onlyAdminsSeeAllTimeEntries: boolean;
    onlyAdminsSeePublicProjectsEntries: boolean;
    trackTimeDownToSecond: boolean;
    projectGroupingLabel: string;
    adminOnlyPages: string[];
    automaticLock: {
        type: string;
        changeDay: string;
        firstDay: string;
        dayOfMonth: number;
        olderThanPeriod: string;
        olderThanValue: number;
    };
    onlyAdminsCreateTag: boolean;
    onlyAdminsCreateTask: boolean;
    isProjectPublicByDefault: boolean;
}

export interface Workspace {
    id: string;
    name: string;
    hourlyRate: HourlyRate;
    memberships: Array<Membership>;
    workspaceSettings: WorkspaceSettings;
    imageUrl: string;
    featureSubscriptionType: 'PREMIUM' | string;
}

export interface Project {
    id: string;
    name: string;
    hourlyRate: HourlyRate;
    clientId: string;
    workspaceId: string;
    billable: boolean;
    memberships: Membership[];
    color: string;
    estimate: {
        estimate: 'PT0S' | string;
        type: 'AUTO' | string;
    };
    archived: boolean;
    duration: string;
    clientName: string;
    note: string;
    public: boolean;
    template: boolean;
}

export interface Client {
    id: string;
    name: string;
    workspaceId: string;
    archived: boolean;
}

export interface User {
    id: string;
    email: string;
    name: string;
    memberships: Membership[];
    profilePicture: string;
    activateWorkspace: string;
    defaultWorkspace: string;
    settings: Record<string, any>; // todo: specify
    status: 'ACTIVE' | string;
}

export interface TimeEntry {
    id: string;
    description: string;
    tagIds: string[];
    userId: string;
    billable: boolean;
    taskId: null | any;
    projectId: string;
    timeInterval: {
        start: string; // ISO8601
        end: string; // ISO8601
        duration: 'PT8H' | string;
    };
    workspaceId: string;
    isLocked: boolean;
    customFieldValues: null | any;
}

export type ClockifyData = {
    users: User[];
    projects: Project[];
    timeEntries: TimeEntry[];
    clients: Client[];
};

const initialData: ClockifyData = {
    users: [],
    projects: [],
    timeEntries: [],
    clients: [],
};

export const ClockifyContext = createContext<ClockifyData>(initialData);

export const ClockifyProvider: React.FC = ({ children }) => {
    const [clockifyData, setClockifyData] = React.useState<ClockifyData>(initialData);
    React.useEffect(
        () =>
            firestoreService
                .collection('kv')
                .doc('clockify_etl')
                .onSnapshot((next) => {
                    setClockifyData(next.data() as any);
                }, console.error),
        [],
    );
    return <ClockifyContext.Provider value={clockifyData}>{children}</ClockifyContext.Provider>;
};
