import React from 'react';
import { ClockifyData, TimeEntry } from '../context/clockifyContext';
import moment from 'moment';
import 'moment/locale/nl';
import { groupBy, pickBy } from 'lodash';
import { Button } from '@blueprintjs/core';

interface Props {
    userId: string | undefined;
    startDate: moment.Moment;
    endDate: moment.Moment;
    clockifyData: ClockifyData;
    billable: boolean;
}

type ProcessedData = Array<{
    projectName: string;
    totalHours: number;
    entries: Array<{
        date: string;
        hours: number;
    }>;
}>;

const preprocess: (props: Props) => ProcessedData = ({
    userId,
    startDate,
    endDate,
    clockifyData,
    billable,
}) => {
    const entryToHours = (entry: TimeEntry) =>
        moment
            .duration(moment(entry.timeInterval.end).diff(moment(entry.timeInterval.start)))
            .asHours();

    const entries = clockifyData.timeEntries
        .filter(
            (entry) =>
                entry.userId === userId &&
                moment(entry.timeInterval.start).isBetween(startDate, endDate),
        )
        .sort((a, b) => a.timeInterval.start.localeCompare(b.timeInterval.start));
    let groupedEntries = groupBy(entries, 'projectId');
    if (billable) {
        groupedEntries = pickBy(
            groupedEntries,
            (val, projectId) =>
                clockifyData.projects.find((project) => project.id === projectId)?.billable,
        );
    }
    return Object.keys(groupedEntries).map((projectId) => ({
        projectName:
            clockifyData.projects.find((project) => project.id === projectId)?.name || projectId,
        entries: groupedEntries[projectId].map((entry) => ({
            date: moment(entry.timeInterval.start).format('dddd DD-MM-YYYY'),
            hours: entryToHours(entry),
        })),
        totalHours: groupedEntries[projectId].reduce((acc, entry) => acc + entryToHours(entry), 0),
    }));
};

const TimesheetReport: React.FC<Props> = (props: Props) => {
    const { userId, startDate, endDate, clockifyData } = props;
    const data = preprocess(props);

    return (
        <div className="timesheet">
            <div className="heading">
                <div className="top">
                    <img alt="logo" className="logo" src="/codecapi.png" />
                    <Button className="hide-print" onClick={window.print} icon="print">
                        Print
                    </Button>
                </div>
                <div className="title">
                    Overzicht van {clockifyData.users.find((user) => user.id === userId)!.name}
                </div>
                <div className="period">
                    periode {startDate.format('DD-MM-YYYY')} t/m {endDate.format('DD-MM-YYYY')}
                </div>
                <div className="container">
                    <div className="info">
                        {/* <div className="company">{data[0].projectName}</div> */}
                        <ul>
                            <li>
                                <div className="data">
                                    <div className="amount">{data.length}</div>
                                    <div className="unit">project(en)</div>
                                </div>
                            </li>
                            <li className="data">
                                <div className="data">
                                    <div className="amount">
                                        {data.reduce(
                                            (days, { entries }) => days + entries.length,
                                            0,
                                        )}
                                    </div>
                                    <div className="unit">dag(en)</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="hourContainer">
                        <div className="totalHours">Totaal uren</div>
                        <div className="hours">
                            {data.reduce((hours, { totalHours }) => hours + totalHours, 0)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="greenTable">
                <table>
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Project</th>
                            <th className="lastColumn">Uren</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(({ projectName, entries }) => (
                            <>
                                {entries.map(({ date, hours }) => (
                                    <tr key={date}>
                                        <td>{date}</td>
                                        <td>{projectName}</td>
                                        <td className="lastColumn">{hours}</td>
                                    </tr>
                                ))}
                            </>
                        ))}
                        <tr className="signing">
                            <td>
                                <p className="signing-intro">Gelieve tekenen voor akkoord.</p>
                                <p className="signing-title">Naam:</p>
                                <p className="signing-title">Datum:</p>
                                <p className="signing-title">Handtekening:</p>
                                <div className="signing-signature"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TimesheetReport;
