import { ClockifyContext, ClockifyData, TimeEntry } from '../context/clockifyContext';
import moment from 'moment';
import Chart, { ChartDataSets } from 'chart.js';
import { groupBy, range } from 'lodash';
import React, { useContext } from 'react';

const colors = [
    '#3b0e0e',
    '#96db4f',
    '#3e39b7',
    '#e8c638',
    '#1d2a6f',
    '#39d394',
    '#e4395b',
    '#47e4ee',
    '#d55c18',
    '#1185e0',
    '#388125',
    '#ba91eb',
    '#1b5522',
    '#e87cae',
    '#092809',
    '#eec3e0',
    '#760f0f',
    '#59bff1',
    '#89701d',
    '#385c87',
    '#f4b377',
    '#201f26',
    '#cad5a7',
    '#6c2151',
    '#c6cdce',
    '#4b340f',
    '#31868b',
    '#b5726d',
    '#133a42',
    '#6e6b68',
];

const entryToHours = (entry: TimeEntry) =>
    moment
        .duration(moment(entry.timeInterval.end).diff(moment(entry.timeInterval.start)))
        .asHours();

const preprocess = (clockifyData: ClockifyData): ChartDataSets[] => {
    const perUser = groupBy(clockifyData.timeEntries, 'userId');

    const hoursPerUserPerMonth = Object.entries(perUser).map(([userId, userEntries]) => ({
        name: clockifyData.users.find((user) => user.id === userId)?.name || '?',
        hoursPerMonth: range(-12, 1).map((monthsAgo) => {
            // entries for this user in this month
            const entries = userEntries.filter(
                (entry) =>
                    Math.round(
                        // difference in month between start of this month and entries' first of month
                        moment
                            .duration(
                                moment(entry.timeInterval.start)
                                    .startOf('month')
                                    .diff(moment().startOf('month')),
                            )
                            .asMonths(),
                    ) === monthsAgo,
            );
            return entries.reduce((acc, entry) => acc + entryToHours(entry), 0);
        }),
    }));

    return hoursPerUserPerMonth.map((entry, idx) => ({
        label: entry.name,
        borderColor: colors[idx],
        fill: false,
        lineTension: 0,
        data: entry.hoursPerMonth.map((hours, monthsAgo) => ({
            t: moment()
                .day(10)
                .subtract(12 - monthsAgo, 'months'),
            y: hours,
        })),
    }));
};

const HoursGraph: React.FC = () => {
    const clockifyData = useContext(ClockifyContext);
    const chart = React.useRef<HTMLCanvasElement | null>(null);

    React.useEffect(() => {
        const data = preprocess(clockifyData);
        if (chart.current && data.length > 0) {
            new Chart(chart.current, {
                type: 'line',

                data: {
                    datasets: data,
                },
                options: {
                    responsive: true,
                    scales: {
                        xAxes: [
                            {
                                type: 'time',
                                time: {
                                    unit: 'month',
                                },
                                ticks: { source: 'data' },
                            },
                        ],
                    },
                },
            });
        }
    }, [chart, clockifyData]);

    return <canvas ref={chart} id="chart" />;
};

export default HoursGraph;
