import { useLocation } from 'react-router-dom';
import React from 'react';

const useSearchParams = <T extends {}>() => {
    const { search } = useLocation();
    React.useEffect(() => {
        // triggers refresh
    }, [search]);
    return Object.fromEntries(new URLSearchParams(window.location.search)) as T;
};

export default useSearchParams;
