import React from 'react';
import GoogleSignInButton from '../components/GoogleSignInButton';
import { AuthContext } from '../context/authContext';
import { Spinner, NonIdealState } from '@blueprintjs/core';

const Login: React.FC = () => {
    const { isLoading, user, firestoreUser } = React.useContext(AuthContext);

    const loading = isLoading || !!(user && !firestoreUser);

    return (
        <div className="login">
            <h1>CodeCapi Timesheets app</h1>
            <p>Gebruik je CodeCapi e-mailadres om in te loggen.</p>
            <GoogleSignInButton disabled={loading} />
            {loading && <Spinner />}
            {firestoreUser && !firestoreUser.admin && (
                <NonIdealState
                    icon={'error'}
                    title="Geen rechten voor deze app."
                    description="Helaas"
                />
            )}
        </div>
    );
};

export default Login;
