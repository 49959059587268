import React, { useContext } from 'react';

import { AuthContext } from '../context/authContext';
import Navigation from '../components/Navigation';
import HoursGraph from '../components/HoursGraph';

const Dashboard: React.FC = () => {
    const { firestoreUser } = useContext(AuthContext);

    if (!firestoreUser) {
        return null;
    }

    return (
        <div className="dashboard">
            <Navigation title="" />
            <h1>Dashboard</h1>
            <div className="content" style={{ width: '100%', flexDirection: 'column' }}>
                <HoursGraph />
            </div>
        </div>
    );
};

export default Dashboard;
