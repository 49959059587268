import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
    Alignment,
    Button,
    Classes,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NavbarHeading,
    Position,
    Tooltip,
} from '@blueprintjs/core';

import { AuthContext } from '../context/authContext';

interface NavigationProps {
    title: string;
}

const Navigation: React.FC<NavigationProps> = (props) => {
    const { firestoreUser, logout } = useContext(AuthContext);
    const { title } = props;
    const fullTitle = title.length ? `CodeCapi Timesheets - ${title}` : 'CodeCapi Timesheets';

    return (
        <Navbar fixedToTop className="bp3-dark">
            <NavbarGroup align={Alignment.LEFT}>
                <NavbarHeading>
                    <Link to="/" style={{ color: 'white' }}>
                        {fullTitle}
                    </Link>
                </NavbarHeading>
                <NavbarDivider />
                <Link to="/">
                    <Button minimal icon="home">
                        Dashboard
                    </Button>
                </Link>
                <Link to="/reports">
                    <Button minimal icon="print">
                        Rapporten
                    </Button>
                </Link>
                {/* <NavbarDivider /> */}
            </NavbarGroup>
            <NavbarGroup align={Alignment.RIGHT}>
                <span>{firestoreUser?.displayName || ''}</span>
                <NavbarDivider />
                <Tooltip content="Log out" position={Position.BOTTOM}>
                    <Button onClick={logout} className={Classes.MINIMAL} icon="log-out" text="" />
                </Tooltip>
            </NavbarGroup>
        </Navbar>
    );
};

export default Navigation;
