import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyCJbmroHJqUaRuRy7ZFQK6v9V1ITup5E8A',
    authDomain: 'codecapi-timesheets.firebaseapp.com',
    databaseURL: 'https://codecapi-timesheets.firebaseio.com',
    projectId: 'codecapi-timesheets',
    storageBucket: 'codecapi-timesheets.appspot.com',
    messagingSenderId: '1031803893337',
    appId: '1:1031803893337:web:fccc7d84cc7a16a18c6348',
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestoreService = firebase.firestore();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
// (window as any).firestore = firestore;
