import React, { useContext } from 'react';

import { ClockifyContext } from '../context/clockifyContext';
import { HTMLSelect, Button, FormGroup, Card, Checkbox } from '@blueprintjs/core';
import { DateRangeInput } from '@blueprintjs/datetime';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import useSearchParams from '../hooks/useSearchParams';

interface State {
    userId: string | undefined;
    startDate: Date | null;
    endDate: Date | null;
    billable: boolean;
}

interface Params {
    userId: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    billable: string | undefined;
}

const ReportForm: React.FC = () => {
    const clockifyData = useContext(ClockifyContext);
    const history = useHistory();
    const params = useSearchParams<Params>();
    const [state, setState] = React.useState<State>({
        userId: params.userId || undefined,
        startDate: (params.startDate
            ? moment(params.startDate)
            : moment().startOf('month')
        ).toDate(),
        endDate: (params.endDate ? moment(params.endDate) : moment().endOf('month')).toDate(),
        billable: params.billable !== 'false',
    });

    return (
        <Card style={{ flexDirection: 'column' }}>
            <h5>Overzicht maken</h5>
            <FormGroup inline label="Persoon">
                <HTMLSelect
                    value={state.userId}
                    onChange={(e) => setState({ ...state, userId: e.currentTarget.value })}>
                    <option value={undefined}>Kies persoon...</option>
                    {clockifyData.users.map((user) => (
                        <option value={user.id} key={user.id}>
                            {user.name}
                        </option>
                    ))}
                </HTMLSelect>
            </FormGroup>
            <FormGroup inline label="Periode">
                <DateRangeInput
                    formatDate={(date) => moment(date).format('DD-MM-YYYY')}
                    onChange={([startDate, endDate]) => {
                        setState({ ...state, startDate, endDate });
                    }}
                    parseDate={(str) => new Date(str)}
                    value={[state.startDate, state.endDate]}
                />
            </FormGroup>
            <FormGroup inline label="Alleen declarabel">
                <Checkbox
                    checked={state.billable}
                    onChange={() => setState({ ...state, billable: !state.billable })}
                />
            </FormGroup>
            <Button
                disabled={!state.userId}
                onClick={() => {
                    history.push(
                        `/reports?userId=${state.userId}&startDate=${moment(
                            state.startDate,
                        ).toISOString()}&endDate=${moment(
                            state.endDate,
                        ).toISOString()}&billable=${state.billable.toString()}`,
                    );
                }}>
                Maak overzicht
            </Button>
        </Card>
    );
};

export default ReportForm;
