import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthContext, AuthProvider } from './context/authContext';
import { FocusStyleManager } from '@blueprintjs/core';

import Login from './views/Login';
import Dashboard from './views/Dashboard';
import { ClockifyProvider } from './context/clockifyContext';
import Reports from './views/Reports';
const Routes: React.FC = () => {
    const { firestoreUser } = React.useContext(AuthContext);
    if (!firestoreUser || !firestoreUser.admin) {
        return <Login />;
    }
    return (
        <ClockifyProvider>
            <Router>
                <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/reports" component={Reports} />
                </Switch>
            </Router>
        </ClockifyProvider>
    );
};

const App: React.FC = () => {
    FocusStyleManager.onlyShowFocusOnTabs();

    return (
        <AuthProvider>
            <Routes />
        </AuthProvider>
    );
};

export default App;
