import React from 'react';
import { ClockifyContext } from '../context/clockifyContext';
import TimesheetReport from '../components/TimesheetReport';
import moment from 'moment';
import Navigation from '../components/Navigation';
import ReportForm from '../components/ReportForm';
import useSearchParams from '../hooks/useSearchParams';

interface Params {
    userId: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    billable: string | undefined;
}

const Reports = () => {
    const { userId, startDate, endDate, billable } = useSearchParams<Params>();
    const clockifyData = React.useContext(ClockifyContext);
    if (clockifyData.users.length === 0) {
        return null;
    }
    return (
        <div className="timesheet">
            <div className="hide-print">
                <Navigation title="Rapporten" />
            </div>
            <div>
                <div className="hide-print">
                    <ReportForm />
                </div>
                {userId && startDate && endDate && (
                    <TimesheetReport
                        userId={userId}
                        startDate={moment(startDate)}
                        endDate={moment(endDate)}
                        clockifyData={clockifyData}
                        billable={billable !== 'false'}
                    />
                )}
            </div>
        </div>
    );
};

export default Reports;
